import { FC, ReactNode, useId } from "react";
import { MdClose } from "react-icons/md";
interface SidebarProps {
  isOpen: boolean;
  onClose: (a: false) => void;
  content?: ReactNode;
  unMountOnClose?: boolean;
  title: string;
  children?: ReactNode;
  className?: string;
  drawerClass?: string;
}
const Drawer: FC<SidebarProps> = ({
  isOpen,
  onClose,
  content,
  title,
  unMountOnClose,
  children,
  className = "",
  drawerClass = "",
}) => {
  const UI = children || content;
  const id = useId();
  return (
    <div className="drawer z-[9999] absolute drawer-end">
      <input
        type="checkbox"
        id={id}
        className="drawer-toggle"
        checked={isOpen}
        onChange={() => onClose(false)}
      />

      <div className="drawer-side z-[9999]">
        <label
          htmlFor={id}
          aria-label="close sidebar"
          className="drawer-overlay"
        ></label>
        <ul
          className={`menu content-start overflow-auto h-full p-4 w-full sm:w-80 lg:w-96 min-h-full xl:w-[40vw] bg-base-100 text-base-content flex-col ${drawerClass} `}
        >
          <div className="flex items-center mb-6">
            <div className="btn btn-ghost transform-none">{title}</div>
            <button
              onClick={() => onClose(false)}
              className="btn btn-neutral btn-xs btn-circle absolute right-2"
            >
              <MdClose />
            </button>
          </div>
          <div className={className}>{!unMountOnClose ? UI : isOpen && UI}</div>
        </ul>
      </div>
    </div>
  );
};

export default Drawer;
