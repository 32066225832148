import { useOrganization } from "@/context/OrganizationContext";
import { useUser } from "@/context/UserContext";
import ImageFromPath from "@/ui/ImageFromPath";
import { formatFileSize, saveFile } from "@/utils/helper";
import { socket } from "@/utils/socket";
import { downloadFile, uploadFile } from "@/utils/supabase";
import { api } from "@/utils/trpc";
import { useMutation } from "@tanstack/react-query";
import format from "date-fns/format";
import { FC, useEffect, useState } from "react";
import { HiPlus } from "react-icons/hi";
import { IoIosSend } from "react-icons/io";
import { MdMarkUnreadChatAlt, MdOutlineDownloading } from "react-icons/md";
import { Mention, MentionsInput } from "react-mentions";
import Drawer from "../drawer/Drawer";

type Props = {
  invoiceId?: string;
  billId?: string;
  expenseId?: string;
  transactionId?: string;
};

const Comments: FC<Props> = (input) => {
  const { data = [] } = api.comments.list.useQuery(input);
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const { userId } = useUser<true>();
  const download = useMutation(downloadFile);

  const { organizationId } = useOrganization<true>();
  const { data: team = [] } =
    api.organizations.team_member.list.useQuery(organizationId);

  const upload = useMutation(uploadFile);

  const create = api.comments.create.useMutation();
  const utils = api.useUtils();

  const handleDownload = async (file: (typeof data)[number]["file"]) => {
    if (!file) return;
    const blob = await download.mutateAsync(file.link);

    const _file = new Blob([blob], { type: file.mimeType });

    saveFile(_file, file.name);
  };

  const handleCreate = async ([doc]: File[] = []) => {
    if (!text && !doc) return;

    let file = null;

    if (doc) {
      const link = await upload.mutateAsync(doc);
      file = {
        link,
        size: formatFileSize(doc.size),
        name: doc.name,
        organizationId,
        mimeType: doc.type,
      };
    }

    await create.mutateAsync({
      ...input,
      text,
      userId,
      file,
      organizationId,
    });
    setText("");
  };

  useEffect(() => {
    socket.on("new-comment", (res) => {
      utils.comments.list.setData(input, (p = []) => [...p, res]);
    });
    return () => {
      socket.off("new-comment");
    };
  }, [input]);
  return (
    <>
      <button
        onClick={() => setOpen(true)}
        className="btn btn-outline btn-accent"
      >
        <MdMarkUnreadChatAlt size={20} />
      </button>
      <Drawer
        title="Comments"
        className="grid gap-4 flex-1 w-full overflow-hidden h-full"
        isOpen={open}
        onClose={() => setOpen(false)}
      >
        <div className="flex-1 grid gap-1.5 h-full w-full overflow-x-hidden overflow-auto">
          {data.map((c) => (
            <div
              key={c.id}
              className={` ${c.file ? "w-fit max-w-3/5" : "w-3/5"}  badge flex-col rounded-md !h-fit items-stretch ${c.userId === userId ? "justify-self-end badge-primary" : "badge-neutral"}`}
            >
              {c.file ? (
                <div className="overflow-hidden">
                  {c.file.mimeType.includes("image") ? (
                    <ImageFromPath
                      className="rounded-md pt-2"
                      path={c.file.link}
                    />
                  ) : (
                    <a
                      href={c.file.link}
                      target="_blank"
                      className="flex items-center"
                    >
                      {c.file.name}
                      <button
                        onClick={() => handleDownload(c.file)}
                        className="btn btn-xs text-gray-500 btn-ghost"
                      >
                        <MdOutlineDownloading size={20} />
                      </button>
                    </a>
                  )}
                </div>
              ) : (
                <p className="text-sm break-all">{c.text}</p>
              )}
              <p className="text-xs text-gray-500 text-right">
                {format(new Date(c.createdAt), "hh:mm aaa")}
              </p>
            </div>
          ))}
        </div>

        <div className="flex self-end items-center w-full gap-2">
          <button className="btn btn-sm btn-primary">
            <input
              type="file"
              className="opacity-0 absolute w-full h-full"
              onChange={(e) => handleCreate(e.target.files!)}
            />
            <HiPlus />
          </button>
          <MentionsInput
            singleLine
            placeholder="Insert @ to select a column"
            className="auto-suggest w-full p-0 h-10"
            allowSuggestionsAboveCursor
            forceSuggestionsAboveCursor
            value={text}
            customSuggestionsContainer={(children) => (
              <div className="p-1 overflow-auto bg-base-300 max-h-44 shadow-lg rounded">
                {children}
              </div>
            )}
            data-cy="computed-expression"
            onChange={(e) => setText(e.target.value)}
          >
            <Mention
              trigger="@"
              displayTransform={(id) => `@${id}`}
              appendSpaceOnAdd
              className="ml-1 z-20"
              markup="@__id__"
              data={team.map((c) => ({ id: c.user.name }))}
              renderSuggestion={(
                suggestion,
                search,
                highlightedDisplay,
                index,
                focused
              ) => {
                return (
                  <div
                    className={`btn ${
                      focused ? "btn-primary" : ""
                    } my-0.5 btn-xs w-full justify-start  `}
                  >
                    {highlightedDisplay}
                  </div>
                );
              }}
            />
          </MentionsInput>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => handleCreate()}
          >
            <IoIosSend />
          </button>
        </div>
      </Drawer>
    </>
  );
};

export default Comments;
