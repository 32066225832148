import Comments from "@/components/comments/Comments";
import AttachProof from "@/components/proof/AttachProof";
import NotFound from "@/components/utils/notfound";
import Spinner from "@/components/utils/spinner";
import { PAYMENT_HOST } from "@/constants";
import AssignCategories from "@/ui/AssignCategories";
import AssignTeam from "@/ui/AssignTeam";
import ImageFromPath from "@/ui/ImageFromPath";
import LineItems from "@/ui/LineItems";
import LoadingSpin from "@/ui/LoadingSpin";
import { PageTitle } from "@/ui/PageTitle";
import PaymentDetails from "@/ui/PaymentDetails";
import PromptModal from "@/ui/PromptModal";
import { formatCurrency, formatId } from "@/utils/helper";
import { uploadFile } from "@/utils/supabase";
import { api } from "@/utils/trpc";
import { useMutation } from "@tanstack/react-query";
import { format } from "date-fns";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { HiChevronRight, HiOutlineLink } from "react-icons/hi";
import { MdDelete, MdEdit } from "react-icons/md";
import { RiArrowGoBackLine } from "react-icons/ri";
import { Link, useNavigate, useParams } from "react-router-dom";
import BillStatus from "./BillStatus";
import ManageFinancialAccount from "./ManageFinancialAccount";
import PaymentHandler from "./PaymentHandler";

const BillPage = () => {
  const { billId = "" } = useParams();

  const { data, isLoading } = api.bills.billById.useQuery(billId, {
    enabled: !!billId,
  });

  const deleteBill = api.bills.deleteBill.useMutation();
  const upload = useMutation(uploadFile);
  const nav = useNavigate();
  const [loading, setLoading] = useState<
    false | "download" | "open" | "upload"
  >(false);

  if (isLoading)
    return (
      <p className="invoice-page loading-text mt-[100px] p-10 font-semibold text-base-content text-center    text-xl">
        <Spinner />
      </p>
    );

  if (!data) return <NotFound title="Bill" />;

  const editable = !["paid", "received", "overdue"].includes(data.status);

  const handleDelete = async () => {
    const confirm = window.confirm(
      "Are you sure you want to delete this invoice?"
    );
    if (!confirm) return;
    await deleteBill.mutateAsync(data.id);
    toast.success("Invoice deleted successfully");
    nav(-1);
  };

  return (
    <div className="pb-8 grid gap-6">
      <PageTitle
        {...{
          title: "Bill",
          subtitle: formatId(data.bill_number, "BILL"),
        }}
      />
      <div className="flex gap-3 justify-between items-end">
        <div className="flex-1 flex items-center gap-5">
          <Link className="link link-secondary link-hover" to="/bills">
            <RiArrowGoBackLine size={26} />
          </Link>
          <AssignTeam
            {...{
              billId: data.id,
              assignees: data.assignees.map((a) => a.team_member_Id),
            }}
          />
        </div>
        <Comments billId={data.id} />
        <div className="flex gap-3 items-center">
          {editable && (
            <>
              <Link
                to={`/bills/update/${data.id}`}
                className="text-white text-base   flex gap-3  items-center   px-1 md:px-3 py-2 bg-base-100 rounded-md "
              >
                <MdEdit className="text-blue-600" size={22} />
              </Link>
              <button
                onClick={handleDelete}
                className="text-white rounded-md text-base   flex gap-3 bg-base-100 items-center   px-1 md:px-3 py-2 relative"
              >
                <LoadingSpin loading={deleteBill.isLoading} />
                <MdDelete className="text-red-500" size={22} />
              </button>
            </>
          )}
        </div>
      </div>
      <div className="flex md:flex-row flex-col gap-4">
        <div className="lg:w-[60%]  grid gap-4">
          <div className="h-fit overflow-hidden w-full bg-base-100 p-4 rounded-lg grid gap-4">
            <p className="label">Vendor</p>
            <div className="btn h-fit  w-full transform-none justify-start">
              <ImageFromPath
                {...{
                  path: data.vendor?.photo,
                }}
                className="w-8 aspect-square rounded-xl"
              />
              <p>{data.vendor?.name}</p>
              <p className="text-xs">
                {"<"}
                {data.vendor?.email}
                {">"}
              </p>
            </div>
            <table className="table">
              <thead className="text-base-content bg-base-300 border border-base-300">
                <tr>
                  <th>Subtotal</th>
                  <th>VAT</th>
                  <th className="w-full text-right">Total</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>{formatCurrency(data.total, data.currency)}</td>
                  <td>
                    <p>{formatCurrency(data.totalVat, data.currency)}</p>
                    <p className="text-xs">
                      ({((data.totalVat * 100) / data.totalAmount).toFixed(2)}
                      %){" "}
                    </p>
                  </td>
                  <td className="text-right">
                    {formatCurrency(data.totalAmount, data.currency)}
                  </td>
                </tr>
              </tbody>
            </table>
            <LineItems rows={data.rows} currency={data.currency} />

            <table className="table">
              <thead className="text-base-content bg-base-300 border border-base-300">
                <tr>
                  <th>Created at</th>
                  <th>Due date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{format(data.createdAt, "dd MMM yyy")}</td>
                  <td>{format(data.dueDate, "dd MMM yyy")}</td>
                </tr>
              </tbody>
            </table>
          </div>
          {data.status === "paid" && (
            <PaymentDetails {...{ data: data.payments[0] }} />
          )}
          <AttachProof {...{ proofs: data.proofs, billId: data.id }} />
        </div>

        <div className="lg:w-[40%] w-full content-start  grid gap-2">
          <BillStatus
            key={data.status}
            {...{
              approvalStatus: data.approvalStatus,
              status: data.status,
              billId: data.id,
            }}
          />

          <div className="flex items-center justify-between z-20">
            <p className="label hidden sm:flex  label-text">Categories</p>
            <div className="w-60">
              <AssignCategories
                {...{
                  assignedCategories: {
                    category: data.category,
                    subCategory: data.subCategory,
                  },
                  billId: data.id,
                }}
              />
            </div>
          </div>

          <div className="w-full z-10 mt-4 justify-between flex">
            <p className="label hidden sm:flex ">GL Account</p>
            <div className="w-60">
              <ManageFinancialAccount
                {...{
                  billId: data.id,
                  financialAccount: data.financialAccount,
                }}
              />
            </div>
          </div>

          <div className="w-full mt-4 justify-between flex">
            <p className="label hidden sm:flex label-text ">Actions</p>
            {data.status !== "paid" && (
              <PaymentHandler
                bills={[data.id]}
                className="btn-accent btn-sm w-60"
              />
            )}
            <div className="w-60 hidden gap-2">
              <PromptModal
                {...{
                  message: `Chose payment option to pay bill <${formatId(
                    data.bill_number,
                    "BILL"
                  )}>:`,
                  buttons: [
                    {
                      label: "Pay now",
                      onClick: () =>
                        window.open(
                          `${PAYMENT_HOST}/bills/${data.id}`,
                          "_blank"
                        ),
                      type: "primary",
                    },
                    {
                      label: "Schedule for later",
                      onClick: () =>
                        window.open(
                          `${PAYMENT_HOST}/bills/${data.id}?schedule=true`,
                          "_blank"
                        ),
                      type: "secondary",
                    },
                  ],
                  render: (toggle) => (
                    <div
                      className="tooltip tooltip-warning tooltip-top"
                      data-tip={
                        data.accountInfoId
                          ? null
                          : "Please add a bank account to this bill to enable the payment link"
                      }
                    >
                      <button
                        className="btn btn-secondary justify-between w-full"
                        disabled={!data.accountInfoId}
                        onClick={() => {
                          if (!data.accountInfoId) return;
                          toggle(true);
                        }}
                      >
                        <HiOutlineLink />
                        <span className="flex-1 text-left">Payment Link</span>
                        <HiChevronRight />
                      </button>
                    </div>
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillPage;
