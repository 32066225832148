import Comments from "@/components/comments/Comments";
import ManageReviewer from "@/components/ManageReview/ManageReviewer";
import AttachProof from "@/components/proof/AttachProof";
import NotFound from "@/components/utils/notfound";
import Spinner from "@/components/utils/spinner";
import { useOrganization } from "@/context/OrganizationContext";
import { useUser } from "@/context/UserContext";
import AssignCategories from "@/ui/AssignCategories";
import AssignTeam from "@/ui/AssignTeam";
import LineItems from "@/ui/LineItems";
import LoadingSpin from "@/ui/LoadingSpin";
import { PageTitle } from "@/ui/PageTitle";
import { formatCurrency, formatId } from "@/utils/helper";
import { api } from "@/utils/trpc";
import { format } from "date-fns";
import { toast } from "react-hot-toast";
import { MdDelete, MdEdit } from "react-icons/md";
import { RiArrowGoBackLine } from "react-icons/ri";
import { Link, useNavigate, useParams } from "react-router-dom";
import PaymentHandler from "../bills/PaymentHandler";
import ExpenseStatus from "./ExpenseStatus";
import ManageFinancialAccount from "./ManageFinancialAccount";

const ExpensePage = () => {
  const { expenseId = "" } = useParams();
  const { userId } = useUser();
  const {
    organization: { requiredApprovalToTransferPayment },
  } = useOrganization<true>();

  const { data, isLoading } = api.expenses.get.useQuery(expenseId, {
    enabled: !!expenseId,
  });

  const deleteExpense = api.expenses.deleteExpense.useMutation();
  const nav = useNavigate();

  if (isLoading)
    return (
      <p className="invoice-page loading-text mt-[100px] p-10 font-semibold text-base-content text-center    text-xl">
        <Spinner />
      </p>
    );

  if (!data) return <NotFound title="Expense" />;

  const editable =
    ["draft", "rejected"].includes(data.status) && userId === data.userId;

  const handleDelete = async () => {
    const confirm = window.confirm(
      "Are you sure you want to delete this invoice?"
    );
    if (!confirm) return;
    await deleteExpense.mutateAsync(data.id);
    toast.success("Invoice deleted successfully");
    nav(-1);
  };

  return (
    <div className="pb-8 grid gap-6">
      <PageTitle
        {...{
          title: "Expense",
          subtitle: formatId(data.expense_number, "EXP"),
        }}
      />
      <div className="flex gap-3 justify-between items-end">
        <div className="flex-1 flex items-center gap-5">
          <Link className="link link-secondary link-hover" to="/expenses">
            <RiArrowGoBackLine size={26} />
          </Link>
          <AssignTeam
            {...{
              expenseId: data.id,
              assignees: data.assignees.map((a) => a.team_member_Id),
            }}
          />
        </div>
        <Comments expenseId={data.id} />
        {editable && (
          <>
            <Link
              to={`/expenses/update/${data.id}`}
              className="btn btn-primary "
            >
              <MdEdit />
            </Link>
            <button onClick={handleDelete} className="btn btn-error">
              <LoadingSpin loading={deleteExpense.isLoading} />
              <MdDelete />
            </button>
          </>
        )}
      </div>
      <div className="flex md:flex-row flex-col gap-4">
        <div className="lg:w-[60%]  grid gap-4">
          <div className="h-fit overflow-hidden w-full bg-base-100 p-4 rounded-lg grid gap-4">
            <p className="label">Staff</p>
            <div className="btn h-fit w-full transform-none justify-start">
              {data.team_member.user.name}
              <p className="text-xs">
                {"<"}
                {data.team_member.user.email}
                {">"}
              </p>
            </div>
            <div className="bg-base-200 rounded-lg px-4 pb-3 w-full justify-start">
              <p className="label">
                Guest Staff: {data.externalGuest && "(External)"}
              </p>
              <div className="flex items-center gap-2">
                {data.guestStaff.map((s, i) => (
                  <p className=" text-sm  font-semibold  " key={s.staff.email}>
                    {s.staff.name}
                  </p>
                ))}
              </div>
            </div>
            <table className="table">
              <thead className="text-base-content bg-base-300 border border-base-300">
                <tr>
                  <th>Subtotal</th>
                  <th>VAT</th>
                  <th className="w-full text-right">Total</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>{formatCurrency(data.total, data.currency)}</td>
                  <td>
                    <p>{formatCurrency(data.totalVat, data.currency)}</p>
                    <p className="text-xs">
                      ({((data.totalVat * 100) / data.totalAmount).toFixed(2)}
                      %){" "}
                    </p>
                  </td>
                  <td className="text-right">
                    {formatCurrency(data.totalAmount, data.currency)}
                  </td>
                </tr>
              </tbody>
            </table>

            <LineItems rows={data.rows} currency={data.currency} />
            <table className="table">
              <thead className="text-base-content bg-base-300 border border-base-300">
                <tr>
                  <th>Created at</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{format(data.createdAt, "dd MMM yyy")}</td>
                  <td>{format(data.date, "dd MMM yyy")}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <AttachProof {...{ proofs: data.proofs, expenseId: data.id }} />
        </div>

        <div className="lg:w-[40%] w-full content-start  grid gap-2">
          <ExpenseStatus
            key={data.status + data.approvalStatus}
            {...{
              expenseUserId: data.userId,
              approvalStatus: data.approvalStatus,
              status: data.status,
              expenseId: data.id,
            }}
          />

          <div className="flex items-center z-20 justify-between">
            <p className="label label-text hidden sm:flex">Categories</p>
            <div className="w-60">
              <AssignCategories
                {...{
                  assignedCategories: {
                    category: data.category,
                    subCategory: data.subCategory,
                  },
                  invoiceId: data.id,
                }}
              />
            </div>
          </div>

          <div className="w-full z-10 mt-4 justify-between flex">
            <p className="label hidden sm:flex ">GL Account</p>
            <div className="w-60">
              <ManageFinancialAccount
                {...{
                  expenseId: data.id,
                  financialAccount: data.financialAccount,
                }}
              />
            </div>
          </div>
          <div className="w-full mt-4 justify-between flex">
            <p className="label hidden sm:flex label-text ">Actions</p>
            {data.status !== "reimbursed" && (
              <PaymentHandler
                expenses={[data.id]}
                className="btn-accent btn-sm w-60"
              />
            )}
          </div>
          {requiredApprovalToTransferPayment && (
            <ManageReviewer
              {...{
                expenseId: data.id,
                reviews: data.reviews,
                team_member_id: data.team_member_id,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ExpensePage;
